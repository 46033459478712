

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/services-avocat-accusations-criminelles-conseils-prix-quebec.jpg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost40 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Les services d’un avocat pour des accusations criminelles et pénales - Soumissions Avocat"
        description="Qu’elles soient fondées ou non, les accusations criminelles portées contre vous auront des conséquences graves si elles ne sont pas traitées adéquatement par des professionnels du droit. Bien que les innocents soient rarement accusés et condamnés à tort dans un système judiciaire comme celui du Canada, l’histoire démontre qu’un tel LIRE PLUS"
        image={LeadImage}>
        <h1>Les services d’un avocat pour des accusations criminelles et pénales</h1>

					
					
						<p>Qu’elles soient fondées ou non, les accusations criminelles portées contre vous auront des conséquences graves si elles ne sont pas traitées adéquatement par des professionnels du droit. Bien que les innocents soient rarement accusés et condamnés à tort dans un système judiciaire comme celui du Canada, l’histoire démontre qu’un tel scénario ne relève pas complètement de la fiction!</p>
<p><StaticImage alt="services-avocat-accusations-criminelles-conseils-prix-quebec" src="../images/services-avocat-accusations-criminelles-conseils-prix-quebec.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Pour éviter d’être pris dans une situation aussi catastrophique que celles d’un film de cinéma américain, apprenez dès maintenant la portée de vos droits fondamentaux. Le respect de ces mêmes droits passe par l’expertise d’un avocat, alors voici ce qu’un tel professionnel criminaliste peut faire pour vous extirper d’une accusation criminelle ou pénale!</p>
<h2>Les droits d’une personne accusée au criminel</h2>
<p>Dans une démocratie comme celle du Canada, les personnes accusées d’avoir commis un crime sont présumées innocentes jusqu’à preuve du contraire. En plus de séparer notre système des « républiques de bananes », un tel principe garantit également plusieurs droits à ces individus. Ceux-ci doivent être respectés autant par les policiers que par les divers intervenants de la poursuite et du système judiciaire.</p>
<p><strong>Silence : </strong> Dès les premiers instants de l’accusation criminelle au moment de l’arrestation, vous avec le droit de demeurer silencieux, de vous faire indiquer les raisons de votre arrestation ainsi que de demander les services d’un avocat. Conseil pratique, exercez ce droit à la lettre, car aucune présomption ne peut être tirée du silence d’une personne quant à sa culpabilité.</p>
<p><strong>Habeas corpus :</strong> Cette expression latine réfère au droit de vérifier la légalité d’une détention auprès d’un juge dans les plus brefs délais. Ainsi, à partir du moment oà vous êtes mis en état d’arrestation et mis en détention provisoire, vous avez le droit de passer devant un juge rapidement pour que celui-ci vérifie la légalité de votre détention.</p>
<p><strong>Liberté provisoire :</strong> Faisant suite au droit au Habeas Corpus, la liberté provisoire sera octroyée à la suite d’une audience devant un juge qui déterminera si la personne accusée représente un danger pour la communauté. Il se basera sur la nature des faits reprochés ainsi que des circonstances qui ont mené à l’arrestation. Comme la personne est toujours présumée innocente, il faut avoir de bonnes raisons de maintenir une personne derrière les barreaux jusqu’à son procès.</p>
<p><strong>Représentation légale :</strong> Peu importe la gravité des crimes reprochés ou leur  amoralité, tout le monde a le droit à un avocat dans le système judiciaire canadien. Il s’agit d’un droit que tous les grands systèmes démocratiques du monde protègent au point même de payer pour les services d’avocats des individus incapables de défrayer de tels coûts eux-mêmes.</p>
<p><strong>Présomption d’innocence :</strong> Nous l’avons dit ou nous le répétons; tout individu accusé d’un crime est présumé innocent jusqu’à ce que le procureur de la poursuite établisse une preuve de sa culpabilité hors de tout doute raisonnable. L’accusé n’a pas la moindre chose à prouver. C’est à la poursuite représentant l’état que ce fardeau revient.</p>
<p><strong>Procès équitable :</strong> Le droit à un procès équitable consiste à une procédure selon les règles de l’art, mais également le droit d’être jugé devant un jury de ses pairs (sauf pour les infractions sommaires). La Charte canadienne des droits et libertés prévoit d’ailleurs explicitement ce droit.</p>
<h2>Acte criminel vs. infraction sommaire</h2>
<p>En droit criminel Canadien, il y a lieu de faire une distinction importante entre les actes criminels et les infractions sommaires. Comme le droit criminel vise à sanctionner des comportements jugés immoraux et que la moralité n’est jamais « tout blanc tout noir », il n’est pas surprenant qu’il y a ait différentes catégories de crimes basés sur la gravité de l’acte.</p>
<p><StaticImage alt="acte-criminel-infraction-sommaire-mixte-contravention-avocat" src="../images/acte-criminel-infraction-sommaire-mixte-contravention-avocat.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
Infraction sommaire :</strong> Pour les comportements jugés comme étant d’une gravité moindre, la loi prévoit des infractions sommaires. Il s’agit de procès criminels plus expéditifs que pour les actes criminels. Par exemple, les vols à l’étalage, la nudité publique et autres crimes plus banaux se règlent pas procédure sommaire.</p>
<p>Contrairement au procès complet, la procédure sommaire ne comprend pas d’enquête préliminaire pour établir la suffisance de la preuve, elle se passe devant un juge seul et sans jury et elle se règle plus rapidement.</p>
<p><strong>L’acte criminel :</strong> Le procès pour un acte criminel vise à sanctionner une transgression sévère au Code criminel et à punir un acte fortement répréhensible. Par exemple, tuer quelqu’un, l’agresser ou encore commettre un quelconque crime à caractère sexuel se qualifiera comme un acte criminel au sens du droit. Même au sein des actes criminels, la loi prévoit une gradation de gravité.</p>
<p>De plus, les actes criminels bénéficient d’une procédure complète, telle qu’une enquête préliminaire, un procès soit devant juge et jury ainsi qu’une représentation sur la peine advenant un verdict de culpabilité.</p>
<p><strong>Infraction mixte : </strong>Lorsque l’action commise pourrait entrer dans l’une ou l’autre des catégories précédentes, c’est au procureur de la couronne que le choix de poursuite intentée reviendra. Il devra user de son pouvoir discrétionnaire pour faire un choix de catégorie et, une fois cette décision prise, le dossier sera traité selon les procédures de cette même catégorie.</p>
<p><strong>Contravention :</strong> On parlera d’une contravention lorsque l’acte reproché ira à l’encontre d’un règlement et non d’une loi. Les règlements ont une valeur moindre qu’une loi dans notre système et visent des aspects plus précis de la vie en communauté. Toutefois, pour assurer leur respect, on prévoit tout de même des sanctions lorsqu’ils ne sont pas respectés qui prennent la forme de contraventions.</p>
<h2>Procès devant juge seul ou devant jury?</h2>
<p>Un des plus grands mystères du système judiciaire pour les gens n’ayant pas de formation légale est le perpétuel dilemme entre le procès devant juge ou devant juge <strong>et</strong> jury. Les adeptes du cinéma sont toujours impressionnés par les discours survoltant d’avocats devant un jury. Toutefois, de tels procès ne sont pas très commun et sont réservés à certains procès seulement.</p>
<p><strong>Juge seul :</strong> Les infraction sommaires tel qu’expliquées ci-haut sont automatiquement tranchées par un juge seul. Pour éviter de monopoliser les cours inutilement, on ne convoque pas un jury pour des infractions tels un vol à l’étalage. Cela couterait une fortune et ne saurait servir la justice.</p>
<p><strong>Choix de l’accusé :</strong> Pour les actes criminels « moins graves », l’accusé a le choix de subir son procès devant un juge seul ou devant un juge et jury. Ce droit est d’ailleurs protégé par la Charte et fait partie de presque tous les systèmes démocratiques. La décision, bien que revenant à l’accusé, est souvent guidée par les avocats de la défense en fonction du type de crime commis. Ceux-ci se basent sur lequel du juges ou du jury est plus susceptible de démontrer de la sympathie envers l’accusé.</p>
<p><strong>Jury obligatoire :</strong> Les procès pour meurtre se décident toujours devant un jury au Canada. Il s’agit du seul type de crime pour lequel il est obligatoire d’avoir recours à un jury assisté d’un juge. Ces procès sont longs et fastidieux en raison de l’important capital humain mobilisé, mais il s’agit d’un mal nécessaire pour servir la justice.</p>
<p><strong>Quel est le rôle du juge dans un procès devant jury? </strong>Lorsque l’accusé choisit de subir son procès devant un juge et jury, le pouvoir décisionnel revient entre les mains du jury et non du juge. Cependant, ce dernier est présent tout au long du procès. Son rôle est de guider le jury sur l’application du droit, l’admissibilité des preuves et d’agir comme arbitre entre les parties. Il s’assure, en d’autres mots, du bon déroulement de l’instance afin que l’accusé bénéficie d’un procès équitable.</p>
<h2>Pouvez-vous engager un avocat pour faire une demande de pardon?</h2>
<p>Au Canada, ce sont quelques millions de personnes qui ont un casier judiciaire. Il y a donc un très grand nombre de citoyens à la quête d’une demande de pardon. Ceux-ci ne savent pas toujours à qui s’adresser, par contre, ce qui peut compliquer la démarche. Engager un avocat simplifiera grandement la démarche!</p>
<p><StaticImage alt="engager-avocat-demande-pardon-canada-prix-quebec" src="../images/engager-avocat-demande-pardon-canada-prix-quebec.jpg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Celui-ci vous aidera à toutes les étapes, telles que celle d’obtenir votre casier judiciaire. Vous devrez vous rendre au poste de police de votre région, y laisser vos empreintes digitales et payes les frais encourus. Par la suite, il vous faudra obtenir les documents de la cour relatifs à votre dossiers, tels que les procès-verbaux, les documents concernant votre condamnation, etc.</p>
<p>Il vous faudra également remplir tous les formulaires de pardon fournis par le Ministère de la Justice du Canada. Ce sont plusieurs formulaires qui doivent être remplis avec <strong>une explication convaincante</strong> des changements dans votre vie qui justifient une demande et surtout l’octroi d’un pardon. C’est surtout avec cette dernière qu’un avocat peut vous assister!</p>
<p>Bref, le pardon n’est pas un droit auquel les criminels ont automatiquement accès. La clémence est un privilège que détient le gouvernement et qu’il octroie à sa guise. Ainsi, en cas de refus, ce n’est pas la peine de poursuivre le gouvernement pour leur refus; rien ne les oblige à l’accorder en aucun cas et il n’y a rien que les tribunaux puissent faire.</p>
<h2>Soumissions Avocat est là pour trouver l’avocat criminaliste qui vous convient!</h2>
<p>Laisser trainer une accusation criminelle est un jeu dangereux! Les histoires d’innocents condamnés à tort font peut-être d’excellentes histoires dramatique, mais elles sont un peu moins intéressantes lorsque c’est vous qui êtes au cœur de l’histoire.</p>
<p><strong>Évitez un scénario aussi lugubre et ne tardez pas à engager un avocat en droit criminel dès que vous faites l’objet d’une accusation. Vous ne savez pas par ou commence?</strong></p>
<p><strong>Confiez votre mission de recherche à Soumissions Avocat! Nous possédons un vaste réseau de partenaires à travers la province pour vous défendre. Il ne suffit que de remplir le formulaire et le tour est joué! Nos services sont gratuits et sans engagement, alors rien ne sert d’hésiter!</strong></p>
    </SeoPage>
)
export default BlogPost40
  